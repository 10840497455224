export const typeOfAccounts = [
  {
    publicLink: "Instagram",
    db: "instagram",
  },
  {
    publicLink: "Facebook",
    db: "facebook",
  },
  {
    publicLink: "TikTok",
    db: "tiktok",
  },
  {
    publicLink: "Spotify",
    db: "spotify",
  },
  {
    publicLink: "Soundcloud",
    db: "soundcloud",
  },
  {
    publicLink: "Press",
    db: "press",
  },
];
